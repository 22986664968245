import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RippleModule } from 'primeng/ripple';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'app-button',
  standalone: true,
  imports: [CommonModule, RippleModule, TooltipModule],
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss',
})
export class ButtonComponent {
  @Input() icon = '';
  @Input() isDropdown: boolean = false;
  @Input() tooltip = '';
  @Input() tooltipPosition = '';
  @Input() label = '';
  @Input() disabled = false;
  @Input() loading = false;
  @Input() type = '';
  @Input() styleClass = '';
  @Input() iconPosition = 'right';
  @Input() variant:
    | 'primary'
    | 'secondary'
    | 'danger'
    | 'outline-primary'
    | 'outline-secondary'
    | 'outline-danger'
    | 'ghost-primary'
    | 'ghost-secondary' = 'primary';
  @Input() size: 'xs' | 'sm' | 'md' | 'lg' | 'xl' = 'md';

  @Output() onClick = new EventEmitter<MouseEvent>();
}
